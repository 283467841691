<template>
  <div class="content">
    <div class="row">
      <div class="col-xl-4">
        <div class="block block-rounded">
          <div class="block-content">
            <router-link
              class="btn btn-lg btn-alt-primary w-100 mb-1"
              to="/youma/add"
              >更新账号</router-link
            >
            <p class="text-center fw-semibold fs-xs text-muted">
              若账号失效请及时更新
            </p>
          </div>
        </div>
        <div
          class="block block-rounded block-link-shadow text-center"
          href="javascript:void(0)"
        >
          <div class="block-header block-header-default">
            <h3 class="block-title">
              <i class="fa fa-fw fa-graduation-cap opacity-50"></i> 基本信息
            </h3>
          </div>
          <div class="block-content block-content-full">
            <div class="push">
              <img alt="" class="img-avatar" :src="info.avatar" />
            </div>
            <div class="fw-semibold mb-1">
              {{ info.nickName }} ({{ info.uin }})·
              <span v-if="info.state === 1" class="text-success" v-cloak
                >运行中</span
              >
              <span v-else class="text-danger" v-cloak>已失效</span>
            </div>
            <div class="fs-sm text-muted"></div>
          </div>
        </div>
        <div class="block block-rounded">
          <div class="block-header block-header-default text-center">
            <h3 class="block-title">
              <i class="fa fa-fw fa-info opacity-50"></i>
              {{
                sportInfo.runType === 4
                  ? "晨跑"
                  : sportInfo.runType === 5
                  ? "长跑"
                  : "重修"
              }}信息
            </h3>
          </div>
          <div class="block-content">
            <table
              class="table table-borderless table-striped"
              style="table-layout: fixed"
            >
              <tbody>
                <tr>
                  <td>
                    <i class="si si-speedometer"></i>
                    <span class="fs-sm"
                      >学期目标：{{ sportInfo.termTask }}公里</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="si si-pie-chart"></i>
                    <span class="fs-sm"
                      >本周达标：{{ sportInfo.weekFinishTime }}次</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="si si-pie-chart"></i>
                    <span class="fs-sm"
                      >本学期达标：{{ sportInfo.finishTime }}次</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="si si-pie-chart"></i>
                    <span class="fs-sm"
                      >本学期达标：{{ sportInfo.finishCount }}公里</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-xl-8">
        <div class="block block-rounded">
          <ul class="nav nav-tabs nav-tabs-block" role="tablist">
            <li
              class="nav-item"
              role="presentation"
              v-for="item in info.sportInfo"
              :key="item.runType"
            >
              <button
                :class="[
                  'nav-link',
                  { active: selectedRunType === item.runType },
                ]"
                id="btabs-static-home-tab"
                @click="changeType(item.runType)"
              >
                {{
                  item.runType === 4
                    ? "晨跑"
                    : item.runType === 5
                    ? "长跑"
                    : "重修"
                }}
              </button>
            </li>
          </ul>

          <div class="block-content">
            <div class="demo-progress">
              还需要跑
              {{ sportInfo.termStandards - sportInfo.finishTime }} 次
              <el-progress
                :percentage="
                  (sportInfo.finishTime / sportInfo.termStandards) * 100
                "
                :format="formatCount"
              />
              还需要跑 {{ sportInfo.termTask - sportInfo.finishCount }} 公里
              <el-progress
                :max="100"
                :percentage="
                  parseInt((sportInfo.finishCount / sportInfo.termTask) * 100)
                "
                :format="formatCount"
              />
            </div>
          </div>
          <div class="block-content">
            <div class="row mb-4">
              <div class="table-responsive">
                <table class="table table-striped table-vcenter">
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <i class="fw-semibold fa fa-2x si si-heart"></i>
                      </td>
                      <td class="fw-semibold">
                        <p class="fw-semibold mb-1">
                          自动跑步
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-primary py-0 px-1 mx-1"
                            data-config="[]"
                            @click="openEdit(true)"
                          >
                            <i class="si si-settings"></i>
                          </button>
                        </p>
                        <p class="fs-xs text-warning mb-0">
                          {{
                            info.runType === 4
                              ? "晨跑"
                              : info.runType === 5
                              ? "长跑"
                              : "重修"
                          }}代跑剩余{{ info.jobCount }}次
                        </p>
                      </td>
                      <td class="d-none d-sm-table-cell">
                        <i class="far fa-clock opacity-75"></i
                        >{{ info.jobTime }}
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <div class="form-check form-switch">
                            <input
                              type="checkbox"
                              v-model="info.job"
                              class="form-check-input"
                              @click="jobs_switch"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-lg-4" style="margin-top: 32px">
                <button
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="execute_run()"
                >
                  <i class="fa fa-paper-plane opacity-50 me-1"></i>立即{{
                    selectedRunType === 4
                      ? "晨跑"
                      : selectedRunType === 5
                      ? "长跑"
                      : "重修"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="block block-rounded">
          <div class="block-header">
            <h3 class="block-title">
              任务运行日志 <small>仅展示最新的50条数据</small>
            </h3>
          </div>
          <div class="block-content block-content-full">
            <el-table
              border
              :data="
                tableData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )
              "
              style="width: 100%"
            >
              <el-table-column prop="id" label="ID" />
              <el-table-column prop="jobName" label="任务类型" />
              <el-table-column prop="response" label="任务响应" />
              <el-table-column prop="createTime" label="运行时间" />
            </el-table>
            <div class="block" style="margin-top: 15px">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                :pager-count="5"
                layout="prev, pager, next"
                :total="tableData.length"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ym-edit v-model:showEdit="showEdit" v-model="info" @done="reload" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { ElLoading, ElNotification } from "element-plus";
import { updateYm, getYmUser, getLogs, runTask } from "@/api/youma";
import YmEdit from "./components/ym-edit.vue";

export default {
  inject: ["reloadUser"],
  name: "Info",
  components: {
    YmEdit,
  },
  data() {
    return {
      info: ref({}),
      tableData: [],
      currentPage: 1,
      total: 50,
      pageSize: 5,
      showEdit: false,
      selectedRunType: null,
      sportInfo: ref({
        runType: null,
        termTask: 0,
        weekFinishTime: 0,
        finishTime: 0,
        finishCount: 0,
      }),
    };
  },
  created() {
    this.reload();
  },
  watch: {
    selectedRunType: function (val) {
      this.sportInfo = this.info.sportInfo.find((item) => item.runType === val);
    },
  },
  methods: {
    changeType(runType) {
      this.selectedRunType = runType;
    },
    reload() {
      let uin = this.$route.query.uin;
      getYmUser(uin)
        .then((res) => {
          this.info = res;

          if (this.info.sportInfo.length > 0) {
            this.selectedRunType = this.info.sportInfo[0].runType;
          }

          if (!res.sportInfo || res.sportInfo.length === 0) {
            ElNotification({
              title: "暂无跑步任务",
              message: "请到宥马App,核实是否有跑步任务",
              type: "warning",
            });
            return;
          }
          if (this.info.isFirstBind == 1) {
            ElNotification({
              title: "欢迎新用户",
              message: "本次跑步免费,请尽情享用",
              type: "success",
            });
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
      getLogs({ uin: uin }).then((res) => {
        this.tableData = res;
      });
    },
    formatCount: (percentage) => {
      const formattedPercentage =
        percentage >= 100 ? "100%" : `${percentage.toFixed(2)}%`;
      return formattedPercentage;
    },
    jobs_switch() {
      updateYm({
        job: !this.info.job,
        uin: this.info.uin,
      })
        .then((message) => {
          this.$message.success(message);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    openEdit(value) {
      this.showEdit = value;
    },
    execute_run() {
      this.$confirm("你确定要开始跑步吗？", "代跑小助手", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      }).then(() => {
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        runTask({
          uin: this.$route.query.uin,
          endTime: this.endTime,
          runType: this.selectedRunType,
        })
          .then((message) => {
            loading.close();
            this.$message.success(message);
            this.reloadUser();
          })
          .catch((err) => {
            loading.close();
            this.$message.error(err.message);
          });
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
  },
};
</script>

<style>
td {
  white-space: nowrap;
}
.demonstration {
  font-size: 14px;
  margin-right: 20px;
}
.demo-progress .el-progress--line {
  margin-bottom: 15px;
  width: 90%;
}
.el-table {
  --el-table-bg-color: transparent;
  --el-table-tr-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
</style>
