export function TMap0() {
  return new Promise(function (resolve, reject) {
    window.init = function () {
      resolve(window.TMap);
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://map.qq.com/api/gljs?v=1.exp&callback=init&key=OQQBZ-EEXEJ-WR6FR-FVV7Y-4YX23-OCBS2";
      script.onerror = reject;
      document.head.appendChild(script);
    };
  });
}

export function assignObject(target, source, excludes) {
  if (typeof target === "object" && target !== null) {
    Object.keys(target).forEach((key) => {
      if (!(excludes == null ? void 0 : excludes.includes(key))) {
        target[key] = source[key];
      }
    });
  }
  return target;
}
