<template>
  <div class="modal-backdrop show" v-show="showDialog"></div>
  <div
    class="modal"
    id="modal-normal"
    aria-labelledby="modal-normal"
    v-show="showDialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="block block-rounded shadow-none mb-0">
          <div class="block-header block-header-default">
            <h3 class="block-title">跑步配置</h3>
            <div class="block-options">
              <button
                type="button"
                class="btn-block-option"
                data-bs-dismiss="modal"
                aria-label="关闭"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div class="block-content fs-sm">
            <el-form ref="form" :model="Infoform" label-width="82px">
              <el-form-item label="跑步次数:" prop="jobCount">
                <el-input-number v-model="Infoform.jobCount" :min="0" />
              </el-form-item>
              <el-form-item label="跑步时间:" prop="jobTime">
                <el-time-select
                  v-model="Infoform.jobTime"
                  :start="start"
                  step="00:15"
                  :end="end"
                  placeholder="Select time"
                />
              </el-form-item>
              <el-form-item label="跑步类型:" prop="runType">
                <el-select v-model="Infoform.runType" placeholder="请选择">
                  <el-option
                    v-for="item in runTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div
            class="block-content block-content-full block-content-sm text-end border-top"
          >
            <button
              type="button"
              class="btn btn-alt-secondary"
              style="margin-right: 2%"
              data-bs-dismiss="modal"
              @click="showDialog = false"
            >
              关闭
            </button>

            <button
              type="button"
              class="btn btn-alt-primary"
              data-bs-dismiss="modal"
              @click="save"
            >
              保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { updateYm } from "@/api/youma";
import { ElMessage } from "element-plus";
import { assignObject } from "@/utils/TMap.js";
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const runTypeList = [
      { value: 4, label: "晨跑" },
      { value: 5, label: "长跑" },
      { value: 6, label: "重修" },
    ];

    const Infoform = ref({
      uin: null,
      jobTime: null,
      jobCount: null,
      runType: null,
    });
    const loading = ref(false);
    const start = ref("07:00");
    const end = ref("22:00");

    const showDialog = computed({
      get: () => props.showEdit,
      set: (val) => {
        emit("update:showEdit", val);
      },
    });

    // 监听数据变化，更新 start 和 end
    watch(
      () => props.modelValue,
      (newValue) => {
        if (
          newValue &&
          newValue.startEndJson &&
          newValue.startEndJson.length > 0
        ) {
          const { startTime, endTime } = JSON.parse(newValue.startEndJson[0]);
          start.value = startTime;
          end.value = endTime;
        }
        assignObject(Infoform.value, newValue);
      }
    );

    const save = () => {
      loading.value = true;
      updateYm(Infoform.value)
        .then((msg) => {
          loading.value = false;
          emit("done");
          showDialog.value = false;
          //弹出提示
          ElMessage.success(msg);
        })
        .catch((e) => {
          loading.value = false;
          ElMessage.error(e.message);
        });
    };

    return {
      Infoform,
      loading,
      start,
      end,
      save,
      showDialog,
      runTypeList,
    };
  },
};
</script>
<style>
/* .el-form-item {
  .el-input {
    width: 260px;
  }
  .el-select {
    width: 260px;
  }
} */
</style>
